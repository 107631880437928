import React, { useMemo } from 'react'
import styled from 'styled-components'

const FilterContainer = styled.div`
  margin-bottom: 2rem;
  width: 100%;
`

const FilterGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
  margin-bottom: 2rem;
`

const FeedbackFilterGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`

const FilterLabel = styled.label`
  display: block;
  font-size: 0.75em;
  width: 100%;
  text-transform: uppercase;
`

const FilterButton = styled.button`
  padding: 8px 40px;
  background-color: ${({ active, theme }) =>
    active ? '#7ab8ff' : theme.colors.cyan};
  border-radius: ${({ theme }) => theme.radius.xxlarge};

  font-size: 0.75em;
  font-family: 'Scto Grotesk A', sans-serif;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02em;

  span {
    font-weight: 700;
  }

  :hover {
    background-color: #7ab8ff;
  }

  transition: background-color ${({ theme }) => theme.transition.duration};
`

const isActive = (a, b) => {
  return a.lower === b.lower && a.upper === b.upper
}

const FeedbackFilterGroup = ({ title, filters, current, handler }) => {
  return (
    <FeedbackFilterGroupContainer>
      <FilterLabel>{title}</FilterLabel>
      <FilterGroup>
        {filters.map((filter, index) => (
          <FilterButton
            key={`${title}-${index}`}
            active={isActive(filter.value, current)}
            onClick={() => handler(filter.value)}
          >
            <span>{filter.label}</span>
            {` (${filter.count} kpl)`}
          </FilterButton>
        ))}
      </FilterGroup>
    </FeedbackFilterGroupContainer>
  )
}

export const FeedbackFilters = ({
  setYearFilter,
  setScoreFilter,
  summary,
  yearFilter,
  scoreFilter,
}) => {
  // Memoize the filters to avoid unnecessary recalculations
  const filters = useMemo(() => {
    const yearFilters = summary.map(item => ({
      label: item.year,
      value: { lower: item.year, upper: item.year },
      count: item.total_count,
    }))

    const scoreFilters =
      summary.find(item => item.year === yearFilter.lower)?.score_counts || {}

    const groupedScoreFilters = [
      {
        label: 'Kaikki',
        value: { lower: 0, upper: 10 },
        count: Object.values(scoreFilters).reduce(
          (sum, count) => sum + count,
          0
        ),
      },
      {
        label: 'Arvosana 10-9',
        value: { lower: 9, upper: 10 },
        count: (scoreFilters[9] || 0) + (scoreFilters[10] || 0),
      },
      {
        label: 'Arvosana 8-5',
        value: { lower: 5, upper: 8 },
        count:
          (scoreFilters[5] || 0) +
          (scoreFilters[6] || 0) +
          (scoreFilters[7] || 0) +
          (scoreFilters[8] || 0),
      },
      {
        label: 'Arvosana 4-0',
        value: { lower: 0, upper: 4 },
        count:
          (scoreFilters[0] || 0) +
          (scoreFilters[1] || 0) +
          (scoreFilters[2] || 0) +
          (scoreFilters[3] || 0) +
          (scoreFilters[4] || 0),
      },
    ]

    return { year: yearFilters, score: groupedScoreFilters }
  }, [summary, yearFilter])

  return (
    <FilterContainer>
      <FeedbackFilterGroup
        title="Vuosi"
        filters={filters.year}
        current={yearFilter}
        handler={setYearFilter}
      />
      <FeedbackFilterGroup
        title="Arvosana"
        filters={filters.score}
        current={scoreFilter}
        handler={setScoreFilter}
      />
    </FilterContainer>
  )
}
