import React, { useEffect, useState } from 'react'
import { orangeTheme } from '../styles/theme'
import styled from 'styled-components'
import axios from 'axios'
import { FeedbackItem } from '../components/nps/feedbackItem'
import { NpsDetailsHeader } from '../components/nps/npsDetailsHeader'
import { NpsScoreSummary } from '../components/nps/npsScoreSummary'
import { FeedbackFilters } from '../components/nps/feedbackFilters'
import Spinner from '../components/loader'
import Button from '../components/buttons/button'
import Layout from '../components/layout'
import { ReactComponent as FilterIcon } from '../images/filter.svg'
import { ReactComponent as CloseIcon } from '../images/close2.svg'
import { MicrosoftClarityScript } from '../lib/ms-clarity'

const API = process.env.GATSBY_NPS_BACKEND

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  max-width: ${({ theme }) => theme.width.contentNew};
  @media screen and (max-width: calc(${({ theme }) =>
      theme.width.contentNew} + 2rem)) {
    padding: 1rem;
  }
`

const FeedbackHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  margin-bottom: 2rem;
  row-gap: 1rem;
  column-gap: 1rem;

  h2 {
    grid-column: 2 / span 1;
    justify-self: center;
    margin-bottom: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    h2 {
      grid-row: 1 / span 1;
      grid-column: 1 / span 1;
    }
  }
`

const FilterButtonContainer = styled.div`
  grid-column: 3 / span 1;
  justify-self: end;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
    justify-self: center;
  }
`
const FilterButton = styled.button`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
  gap: 0.5rem;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  padding: 0.5rem 1.5rem;
  border-radius: ${({ theme }) => theme.radius.xxlarge};
`

const FeedbackList = styled.ul`
  padding: 0;
  margin: 0;
`

const EmptyDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100v;
`
const NpsDetails = () => {
  const [summary, setSummary] = useState([])
  const [feedbacks, setFeedbacks] = useState([])
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [yearFilter, setYearFilter] = useState({ lower: 2024, upper: 2024 })
  const [scoreFilter, setScoreFilter] = useState({ lower: 9, upper: 10 })

  useEffect(() => {
    fetchSummary()
    return () => {
      setSummary([])
    }
  }, [])

  useEffect(() => {
    fetchFeedback(yearFilter, scoreFilter, offset)
    return () => {
      setFeedbacks([])
    }
  }, [])

  const handleYearChange = filter => {
    setYearFilter({ lower: filter.lower, upper: filter.upper })
    clearAndFetchFeedback(filter, scoreFilter)
  }

  const handleScoreChange = filter => {
    setScoreFilter({ lower: filter.lower, upper: filter.upper })
    clearAndFetchFeedback(yearFilter, filter)
  }

  const clearAndFetchFeedback = (yearFilter, scoreFilter) => {
    setOffset(0)
    setFeedbacks([])
    fetchFeedback(yearFilter, scoreFilter, 0)
  }

  const fetchSummary = () => {
    axios
      .get(`${API}/summary`)
      .then(response => {
        setSummary(response.data)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  const fetchFeedback = (yearFilter, scoreFilter, offset) => {
    setIsLoading(true)
    axios
      .get(
        `${API}/feedback?offset=${offset}&lower=${scoreFilter.lower}&upper=${scoreFilter.upper}&lowerYear=${yearFilter.lower}&upperYear=${yearFilter.upper}`
      )
      .then(response => {
        setIsLoading(false)
        setFeedbacks(previousData => [
          ...previousData,
          ...response.data.feedbacks,
        ])
        setOffset(
          previousOffset => previousOffset + response.data.feedbacks.length
        )
        setHasMore(response.data.summary.hasMore)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  const countTotal = summary => {
    return summary.length > 0
      ? summary.reduce((acc, year) => {
          return acc + parseInt(year.total_count)
        }, 0)
      : 0
  }

  const showHideFilters = () => {
    setShowFilters(!showFilters)
  }

  return (
    <Layout title={'Asiakkaidemme palautteita'} navbarTheme={orangeTheme}>
      <NpsDetailsHeader />
      <NpsScoreSummary />
      <FeedbackContainer>
        <FeedbackHeader>
          <h2>{countTotal(summary)} kpl kirjallista palautetta</h2>
          <FilterButtonContainer onClick={showHideFilters}>
            {showFilters ? (
              <FilterButton>
                Sulje
                <CloseIcon />
              </FilterButton>
            ) : (
              <FilterButton>
                Suodata
                <FilterIcon />
              </FilterButton>
            )}
          </FilterButtonContainer>
        </FeedbackHeader>
        {showFilters && (
          <FeedbackFilters
            setYearFilter={handleYearChange}
            setScoreFilter={handleScoreChange}
            summary={summary}
            yearFilter={yearFilter}
            scoreFilter={scoreFilter}
          />
        )}
        <FeedbackList>
          {feedbacks.map((feedback, index) => (
            <FeedbackItem
              key={`feedback-${index}`}
              feedback={{
                nps_score: feedback.nps_score,
                comment: feedback.comment,
              }}
            />
          ))}
        </FeedbackList>
        {isLoading && (
          <EmptyDataContainer>
            <Spinner />
          </EmptyDataContainer>
        )}
        {!isLoading && hasMore && (
          <Button
            onClick={() => fetchFeedback(yearFilter, scoreFilter, offset)}
          >
            Näytä lisää
          </Button>
        )}
      </FeedbackContainer>
    </Layout>
  )
}

export function Head() {
  return <MicrosoftClarityScript />
}

export default NpsDetails
