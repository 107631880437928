import React from 'react'
import styled from 'styled-components'

const HeaderWrapper = styled.div`
  display: grid;
  width: 100%;
  justify-items: center;
  margin-bottom: 2rem;
  margin-top: calc(
    ${props => props.theme.navHeight.lg} + ${props => props.theme.navHeight.min}
  );

  grid-template-rows: 3fr 3fr 2fr;
  grid-template-columns: 1;

  @media screen and (max-width: ${props => props.theme.breakpoints.md.max}) {
    height: auto;
    margin-top: ${props => props.theme.navHeight.min};
  }
`

const HeaderBackground = styled.div`
  grid-row: 1 / span 2;
  grid-column: 1;
  background-color: #ffcc94;
  width: 100%;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    display: none;
  }
`

const HeaderContentWrapper = styled.div`
  display: grid;
  grid-row: 1 / span 3;
  grid-column: 1;
  justify-content: center;
  padding: 2rem 2rem 0 2rem;
  max-width: ${props => props.theme.width.max};

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    background-color: #ffcc94;
    position: relative;
  }
`

const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto repeat(6, 1fr);
  grid-column-gap: 1.5rem;
  grid-row-gap: 2rem;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    padding-bottom: ${props => props.theme.spacing.large};
  }
`

const HeaderTitle = styled.h1`
  grid-row: 1 / 3;
  grid-column: 1 / 7;
  font-size: 3.5rem;
  padding-right: 5rem;
  margin: 0;

  span {
    font-style: italic;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    grid-column: 1;
    grid-row: 2;
  }
`

const HeaderBody = styled.p`
  grid-row: 1 / 1;
  grid-column: 7 / 13;

  margin: 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    grid-column: 1;
    grid-row: 3;
    margin-bottom: ${props => props.theme.spacing.large};
  }
`

const HeaderScoreBlock = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.medium};

  h1 {
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 0.8rem;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    padding: 0 ${props => props.theme.spacing.large};
  }
`

const HeaderScore = ({ score, description, className }) => {
  return (
    <HeaderScoreBlock className={className}>
      <h1>{score}</h1>
      <p>{description}</p>
    </HeaderScoreBlock>
  )
}

const HeaderScoreNps = styled(HeaderScore)`
  grid-row: 2 / 3;
  grid-column: 7 / span 2;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    grid-row: 4;
    grid-column: 1;
  }
`
const HeaderScoreService = styled(HeaderScore)`
  grid-row: 2 / 3;
  grid-column: 9 / span 2;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    grid-row: 5;
    grid-column: 1;
  }
`
const HeaderScoreSolutions = styled(HeaderScore)`
  grid-row: 2 / 3;
  grid-column: 11 / span 2;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    grid-row: 6;
    grid-column: 1;
  }
`

const HeaderImage = styled.div`
  grid-row: 3 / 8;
  grid-column: 1 / 13;
  border-radius: 2rem;
  background-color: #ccc;
  background-image: url('https://images.ctfassets.net/3gh7jphuo2o7/5eMys8RwG51ehvD4UShR1p/9cdb815cea22999eec65e71e9d226732/Alexandria-kokemuksia-asiakaspalautteet-hero.png');
  background-size: cover;
  background-position: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs.max}) {
    grid-row: 1;
    grid-column: 1;
  }
`

export const NpsDetailsHeader = () => {
  return (
    <HeaderWrapper>
      <HeaderBackground />
      <HeaderContentWrapper>
        <HeaderGrid>
          <HeaderTitle>
            Tätä mieltä <span>asiakkaamme</span> ovat sijoitusneuvonnasta
          </HeaderTitle>
          <HeaderBody>
            Totuus tulee asiakkaiden suusta, sanotaan. Vaikka asiakkaamme
            antavat palvelullemme arvosanaksi kiitettävän 9,4/10, haluamme, että
            kuulet suoraan asiakkailtamme, millaisia kokemuksia heillä oikeasti
            on palvelustamme.
          </HeaderBody>
          <HeaderScoreNps
            score="8,9"
            description="Kuinka todennäköisesti suosittelisit Alexandrian palveluita lähipiirillesi?"
          />
          <HeaderScoreService
            score="9,4"
            description="Kuinka tyytyväinen olet henkilökohtaisen neuvonantajasi palveluun?"
          />
          <HeaderScoreSolutions
            score="9,1"
            description="Kuinka tyytyväinen olet sinulle suositeltuihin ratkaisuihin?"
          />
          <HeaderImage />
        </HeaderGrid>
      </HeaderContentWrapper>
    </HeaderWrapper>
  )
}
